<template>
  <div v-if="!angemeldeterMitarbeiter" class="container-fluid tile-container">
    <div class="row mt-70-px">
      <div class="col-4"></div>
      <div class="col-4">
        <div class="row">
          <div class="col-xl-12 col-lg-6 col-md-6">
            <div class="tile-categorie-frame">
              <div class="tile-categorie">
                <div class="text-right m-4">
                  <div class="language-selection">
                    <b-button
                      variant="outline-primary"
                      class="mx-1"
                      size="sm"
                      @click="language = 'de'"
                      :class="language === 'de' ? 'active' : ''"
                      >DE</b-button
                    >
                    <b-button
                      variant="outline-primary"
                      class="mr-1"
                      size="sm"
                      @click="language = 'en'"
                      :class="language === 'en' ? 'active' : ''"
                      >EN</b-button
                    >
                    <b-button
                      variant="outline-primary"
                      class="mr-1"
                      size="sm"
                      @click="language = 'fr'"
                      :class="language === 'fr' ? 'active' : ''"
                      >FR</b-button
                    >
                    <b-button
                      variant="outline-primary"
                      class="mr-1"
                      size="sm"
                      @click="language = 'it'"
                      :class="language === 'it' ? 'active' : ''"
                      >IT</b-button
                    >
                  </div>
                </div>
                <div class="header text-center">
                  <span>{{ resetpassword[language] }}</span>
                </div>
                <div class="m-4" v-if="!emailSent">
                  <div class="mb-3 text-center">
                    {{ enteremailaddress[language] }}
                  </div>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span id="username" class="input-group-text">
                        <font-awesome-icon
                          icon="fa-regular fa-envelope"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <input
                      type="email"
                      name="email"
                      class="form-control"
                      :placeholder="emailaddress[language]"
                      aria-describedby="email"
                      autofocus
                      autocapitalize="off"
                      spellcheck="false"
                      autocorrect="off"
                      v-model.trim="email"
                    />
                  </div>
                  <div
                    class="text-center hover-medium-blue mb-3"
                    style="cursor: pointer"
                    @click="navigateToLogin"
                  >
                    {{ backtothelogin[language] }}
                  </div>
                  <button
                    class="btn btn-primary w-100 mb-3"
                    @click="resetPassword"
                    :disabled="!email"
                  >
                    {{ requestlinktoreset[language] }}
                  </button>
                </div>

                <div class="m-4" v-if="emailSent">
                  <div class="mb-3 text-center">
                    <h5>
                      {{ sentemailto[language] }} <br />
                      <span style="text-decoration: underline">{{
                        email
                      }}</span>
                    </h5>
                  </div>
                  <div class="row align-items-center;">
                    <div class="col-10">
                      <div class="mt-3">{{ nomessagereceived[language] }}</div>
                      <ul class="mb-3">
                        <li>{{ checkspamfolder[language] }}</li>
                        <li>{{ checkdetails[language] }}</li>
                        <li>{{ waitbeforetryingagain[language] }}</li>
                      </ul>
                    </div>
                  </div>
                  <div
                    class="align-items-center hover-medium-blue text-center"
                    style="cursor: pointer"
                    @click="navigateToLogin"
                  >
                    {{ backtothelogin[language] }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4"></div>
    </div>
  </div>
</template>

<script>
//import Api from "@/Api";
import axios from "axios";
import server from "../server";

export default {
  name: "PasswortReset",
  data() {
    return {
      email: null,
      emailSent: false,
      language: null,
      resetpassword: {
        de: "Passwort zurücksetzen",
        en: "Reset password",
        fr: "Réinitialiser le mot de passe",
        it: "Reimpostare la password",
      },
      enteremailaddress: {
        de: "Bitte fülle die E-Mail-Adresse ein, die mit deinem Konto verknüpft ist.",
        en: "Please enter the email address associated with your account.",
        fr: "Remplis l'adresse e-mail associée à ton compte.",
        it: "Inserire l'indirizzo e-mail associato al proprio account.",
      },
      emailaddress: {
        de: "E-Mail-Adresse",
        en: "E-mail address",
        fr: "Adresse électronique",
        it: "Indirizzo e-mail",
      },
      backtothelogin: {
        de: "Zurück zum Login",
        en: "Back to the login",
        fr: "Retour à la connexion",
        it: "Torna al login",
      },
      requestlinktoreset: {
        de: "Link zum Zurücksetzen anfordern",
        en: "Request link to reset",
        fr: "Demander un lien de réinitialisation",
        it: "Richiedi il link per il reset",
      },
      sentemailto: {
        de: "Sollte die eingegebene E-Mail-Adresse registriert sein, haben wir eine E-Mail gesendet an:",
        en: "If the e-mail address you entered is registered, we have sent an e-mail to:",
        fr: "Si l'adresse e-mail saisie est enregistrée, nous avons envoyé un e-mail à :",
        it: "Se l'indirizzo e-mail inserito è registrato, abbiamo inviato un'e-mail a:",
      },
      nomessagereceived: {
        de: "Keine Nachricht erhalten?",
        en: "No message received?",
        fr: "Vous n'avez pas reçu de message ?",
        it: "Nessun messaggio ricevuto?",
      },
      checkspamfolder: {
        de: "Überprüfen Sie Ihren Spam-Ordner",
        en: "Check your spam folder",
        fr: "Vérifier votre dossier de spam",
        it: "Controllare la cartella spam",
      },
      checkdetails: {
        de: "Überprüfen Sie Ihre Angaben",
        en: "Check your details",
        fr: "Vérifiez vos données",
        it: "Controlla i tuoi dati",
      },
      waitbeforetryingagain: {
        de: "Warten Sie bitte 15 Minuten, bevor Sie es erneut versuchen",
        en: "Please wait 15 minutes before trying again",
        fr: "Veuillez attendre 15 minutes avant de réessayer",
        it: "Attendere 15 minuti prima di riprovare",
      },
    };
  },
  computed: {
    resetPW_URL() {
      if (process.env.NODE_ENV === "development") {
        return `${server.url}/password/reset/`;
      } else {
        return "/password/reset/";
      }
    },
  },
  methods: {
    async resetPassword() {
      const params = new URLSearchParams();
      params.append("email", this.email);
      this.emailSent = true;
      try {
        axios.post(this.resetPW_URL, params);
      } catch (error) {
        console.log(error);
      }
    },

    navigateToLogin() {
      this.$router.push({
        name: "login",
        query: {
          lang: this.language,
        },
      });
    },
  },
  mounted() {
    this.language =
      this.$route.query.lang || navigator.language || navigator.userLanguage;
    this.language = this.language.slice(0, 2);
    if (
      this.language !== "de" &&
      this.language !== "en" &&
      this.language !== "fr" &&
      this.language !== "it"
    ) {
      this.language = "de";
    }
  },
};
</script>

<style lang="scss" scoped>
.form-signin {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 400px;
  padding: 15px;
}

.language-selection button {
  width: 30px;
}
</style>
